<template>
  <div class="pay-info columns__column-2">
    <div class="settings__prompt">
      Заполните личную информацию профиля, для автоматического заполнения форм в
      разделе Формы
    </div>
    <div class="history">
      <div>
        <p class="text form__text">Выберите период</p>
        <select class="form__select input">
          <option value="USA">За месяц</option>
          <option value="Russia">За день</option>
          <option value="Russia">За год</option>
        </select>
      </div>
      <div class="history__checkbox">
        <div class="history__checkbox-flex">
          <div>
            <label for="checkbox-1" class="history__label">
              <picture>
                <source
                  srcset="/img/pay-info/blue-nike-checkbox.svg"
                  type="image/webp"
                />
                <img src="/img/pay-info/blue-nike-checkbox.svg" alt="nike" />
              </picture>
            </label>
            <input
              id="checkbox-1"
              type="checkbox"
              checked
              class="history__form-checkbox"
            />
          </div>
          <p class="history__form-text">Все операции</p>
        </div>
        <div class="history__checkbox-flex">
          <div>
            <label
              for="checkbox-1"
              class="history__label history__label-not-active"
            >
              <picture>
                <source
                  srcset="/img/pay-info/blue-nike-checkbox.svg"
                  type="image/webp"
                />
                <img src="/img/pay-info/blue-nike-checkbox.svg" alt="nike" />
              </picture>
            </label>
            <input
              id="checkbox-1"
              type="checkbox"
              checked
              class="history__form-checkbox"
            />
          </div>
          <p class="history__form-text">Пополнение</p>
        </div>
        <div class="history__checkbox-flex">
          <div>
            <label for="checkbox-1" class="history__label">
              <picture>
                <source
                  srcset="/img/pay-info/blue-nike-checkbox.svg"
                  type="image/webp"
                />
                <img src="/img/pay-info/blue-nike-checkbox.svg" alt="nike" />
              </picture>
            </label>
            <input
              id="checkbox-1"
              type="checkbox"
              checked
              class="history__form-checkbox"
            />
          </div>
          <p class="history__form-text">Списание</p>
        </div>
      </div>
      <article class="history__table">
        <div class="history__table-str history__table-headers">
          <div class="history__table-td column-1">#</div>
          <div class="history__table-td column-2">
            <div class="history__table-arrow">
              <div class="arrow-up">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table.svg"
                    type="image/webp"
                  />
                  <img src="/img/pay-info/arrow-table.svg" alt="arrow-table" />
                </picture>
              </div>
              <div class="arrow-down">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table-down.svg"
                    type="image/webp"
                  />
                  <img
                    src="/img/pay-info/arrow-table-down.svg"
                    alt="arrow-table"
                  />
                </picture>
              </div>
            </div>
            <p>Дата</p>
          </div>
          <div class="history__table-td column-3">
            <div class="history__table-arrow">
              <div class="arrow-up">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table.svg"
                    type="image/webp"
                  />
                  <img src="/img/pay-info/arrow-table.svg" alt="arrow-table" />
                </picture>
              </div>
              <div class="arrow-down">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table-down.svg"
                    type="image/webp"
                  />
                  <img
                    src="/img/pay-info/arrow-table-down.svg"
                    alt="arrow-table"
                  />
                </picture>
              </div>
            </div>
            <p>Тип операции</p>
          </div>
          <div class="history__table-td column-4">
            <div class="history__table-arrow">
              <div class="arrow-up">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table.svg"
                    type="image/webp"
                  />
                  <img src="/img/pay-info/arrow-table.svg" alt="arrow-table" />
                </picture>
              </div>
              <div class="arrow-down">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table-down.svg"
                    type="image/webp"
                  />
                  <img
                    src="/img/pay-info/arrow-table-down.svg"
                    alt="arrow-table"
                  />
                </picture>
              </div>
            </div>
            <p>Наименование</p>
          </div>
          <div class="history__table-td column-5">
            <div class="history__table-arrow">
              <div class="arrow-up">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table.svg"
                    type="image/webp"
                  />
                  <img src="/img/pay-info/arrow-table.svg" alt="arrow-table" />
                </picture>
              </div>
              <div class="arrow-down">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table-down.svg"
                    type="image/webp"
                  />
                  <img
                    src="/img/pay-info/arrow-table-down.svg"
                    alt="arrow-table"
                  />
                </picture>
              </div>
            </div>
            <p>Сумма операции</p>
          </div>
          <div class="history__table-td column-6">
            <div class="history__table-arrow">
              <div class="arrow-up">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table.svg"
                    type="image/webp"
                  />
                  <img src="/img/pay-info/arrow-table.svg" alt="arrow-table" />
                </picture>
              </div>
              <div class="arrow-down">
                <picture>
                  <source
                    srcset="/img/pay-info/arrow-table-down.svg"
                    type="image/webp"
                  />
                  <img
                    src="/img/pay-info/arrow-table-down.svg"
                    alt="arrow-table"
                  />
                </picture>
              </div>
            </div>
            <p>Статус</p>
          </div>
        </div>
        <div class="history__table-str">
          <p class="history__table-td column-1">1</p>
          <p class="history__table-td column-2">01.01.2021</p>
          <p class="history__table-td column-3">Пополнение</p>
          <p class="history__table-td column-4">Пополнение с карты</p>
          <p class="history__table-td column-5">7 000</p>
          <p class="history__table-td column-6">Завершено</p>
        </div>
        <div class="history__table-str">
          <p class="history__table-td column-1">1</p>
          <p class="history__table-td column-2">01.01.2021</p>
          <p class="history__table-td column-3">Пополнение</p>
          <p class="history__table-td column-4">Пополнение с карты</p>
          <p class="history__table-td column-5">7 000</p>
          <p class="history__table-td column-6">Завершено</p>
        </div>
        <div class="history__table-str">
          <p class="history__table-td column-1">1</p>
          <p class="history__table-td column-2">01.01.2021</p>
          <p class="history__table-td column-3">Пополнение</p>
          <p class="history__table-td column-4">Пополнение с карты</p>
          <p class="history__table-td column-5">7 000</p>
          <p class="history__table-td column-6">Завершено</p>
        </div>
        <div class="history__table-str">
          <p class="history__table-td column-1">1</p>
          <p class="history__table-td column-2">01.01.2021</p>
          <p class="history__table-td column-3">Пополнение</p>
          <p class="history__table-td column-4">Пополнение с карты</p>
          <p class="history__table-td column-5">7 000</p>
          <p class="history__table-td column-6">Завершено</p>
        </div>
        <div class="history__table-str">
          <p class="history__table-td column-1">1</p>
          <p class="history__table-td column-2">01.01.2021</p>
          <p class="history__table-td column-3">Пополнение</p>
          <p class="history__table-td column-4">Пополнение с карты</p>
          <p class="history__table-td column-5">7 000</p>
          <p class="history__table-td column-6">Завершено</p>
        </div>
        <div class="history__table-str">
          <p class="history__table-td column-1">1</p>
          <p class="history__table-td column-2">01.01.2021</p>
          <p class="history__table-td column-3">Пополнение</p>
          <p class="history__table-td column-4">Пополнение с карты</p>
          <p class="history__table-td column-5">7 000</p>
          <p class="history__table-td column-6">Завершено</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfilePaymentBalance",
};
</script>

<style>
.history {
    margin-top: 128px;
}
</style>