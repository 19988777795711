<template>
  <div class="pay-info columns__column-2">
    <div class="settings__prompt">
      Заполните личную информацию профиля, для автоматического заполнения форм в
      разделе Формы
    </div>
    <div class="pay-info__cards">
      <div
        class="card"
        :class="{
          [`pay-info__cards-${card.type}`]: true,
          'card-active': card.id === 1,
        }"
        v-for="card in cards"
        :key="card.id"
      >
        <div class="mastercard__img">
          <div></div>
          <picture>
            <source
              :srcset="`/img/pay-info/${card.type}.svg`"
              type="image/webp"
            />
            <img
              :src="`/img/pay-info/${card.type}.svg`"
              :alt="`${card.type}`"
            />
          </picture>
        </div>
        <p>**** **** **** {{ card.number }}</p>
      </div>
    </div>
    <form class="pay-info__form" ref="form" @submit.prevent="sendForm">
      <h2 class="pay-info__form-title">Добавить карту</h2>
      <article class="entity__flex">
        <Input
          @value-input="saveValue"
          custom_class="form__last-name"
          :error="errors.number"
          field="number"
          placeholder="1234 5678 9012 3456"
          title="Номер карты"
          type="text"
          mask="#### #### #### ####"
        />
        <Input
          @value-input="saveValue"
          custom_class="form__name"
          :error="errors.card_user"
          field="card_user"
          placeholder="IVAN IVANOV"
          title="Держатель карты"
          type="text"
          :mask="{
            mask: 'O* O*',
            tokens: { O: { pattern: /[a-zA-Z\-\']/, uppercase: true } },
          }"
        />
      </article>
      <article class="entity__flex">
        <Input
          @value-input="saveValue"
          custom_class="form__last-name"
          :error="errors.valid_date"
          field="valid_date"
          placeholder="01/22"
          title="Срок действия"
          type="text"
          mask="##/##"
        />
        <Password
          @value-input="saveValue"
          :error="errors.cvv"
          field="cvv"
          title="CVV"
          value="123"
          mask="###"
        />
      </article>
      <article>
        <input
          type="submit"
          placeholder=""
          class="form__submit"
          value="Принять"
        />
      </article>
    </form>
  </div>
</template>

<script>
import { API } from "../../../helpers/api";

import Input from "../../../components/utils/Input.vue";
import Password from "../../../components/utils/Password.vue";

export default {
  name: "ProfilePaymentCommon",
  components: {
    Input,
    Password,
  },
  data() {
    return {
      cards: [],
      values: {
        number: "",
        card_user: "",
        valid_date: "",
        cvv: "",
      },
      errors: {
        number: "",
        card_user: "",
        valid_date: "",
        cvv: "",
      },
    };
  },
  async mounted() {
    try {
      const response = await API.send("getBankCards", {}, "GET");

      if (response.status !== 0) {
        alert(response.user_message);

        if (response.status === 403) {
          this.$router.push({ path: "/auth" });
        }
        return;
      }

      this.cards = response.cards;
    } catch (e) {
      alert("Произошла неизвестная ошибка. Попробуйте позже.");
      console.error(e);
    }
  },
  methods: {
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
    async sendForm() {
      const valid_data = this.values.valid_date.split("/");
      const valid_json = {
        month: Number(valid_data[0]),
        year: Number(valid_data[1]),
      };

      if (valid_json.month <= 0 || valid_json.month > 12) {
        alert("Указан неверный месяц срока действия");
        return;
      }

      if (valid_json.year < new Date().getFullYear() % 1000) {
        alert("Указан недействительный год срока действия");
        return;
      }

      try {
        const response = await API.send(
          "addBankCard",
          {
            number: this.values.number.replace(/ /g, ''),
            holder_name: this.values.card_user,
            valid_date: JSON.stringify(valid_json),
            cvv: this.values.cvv,
          },
          "POST"
        );

        if (response.status !== 0) {
          alert(response.user_message);
          return;
        }

        alert("Карта добавлена!");

        this.cards.push(response.card);
        this.$refs.form.reset();
      } catch (e) {
        alert("Неизвестная ошибка. Попробуйте позже.");
      }
    },
  },
};
</script>

<style>
.pay-info__cards {
  margin-top: 128px;
}
</style>