<template>
  <div class="pay-info columns__column-2">
    <div class="settings__prompt">
      Заполните личную информацию профиля, для автоматического заполнения форм в
      разделе Формы
    </div>
    <div class="score">
      <h2 class="score__title">Баланс: 5 200</h2>
      <div class="score__sum">
        <p class="score__sum-text">Введите сумму</p>
        <input type="number" class="score__sum-input input" value="10000" />
      </div>
      <div class="score__choise-card">
        <h2 class="score__title">Выберите карту для пополнения</h2>
        <div class="score__form">
          <h2 class="score__title">**** **** **** 5566</h2>
          <div class="score__card">**** **** **** 1232</div>
          <div class="score__card score__card-active">**** **** **** 5566</div>
          <input
            type="text"
            class="score__submit form__submit"
            value="Пополнить"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfilePaymentBalance",
};
</script>

<style>
.score {
    margin-top: 128px;
}
</style>